<template>
  <div id="ThePagination">
    <nav aria-label="Pagination">
      <hr class="my-0" />
      <ul class="pagination justify-content-center my-4">
        <li class="page-item" :class="{ disabled: isInFirstPage }">
          <button
            type="button"
            class="page-link"
            :disabled="isInFirstPage"
            @click="onClickPage(1)"
          >
            جدیدترین
          </button>
        </li>
        <li
          class="page-item"
          aria-current="page"
          v-for="page in pages"
          :key="page.name"
          :class="{ active: page.isActive }"
        >
          <button
            type="button"
            @click="onClickPage(page.name)"
            class="page-link"
            :disabled="page.isActive"
          >
            {{ page.name }}
          </button>
        </li>
        <li class="page-item" :class="{ disabled: isInLastPage }">
          <button
            type="button"
            class="page-link"
            :disabled="isInLastPage"
            @click="onClickPage(totalPage)"
          >
            قدیمی ترین
          </button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: "ThePagination",
  props: {
    totalPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    maxVisibleBtn: {
      type: Number,
      required: false,
      default: 5,
    },
  },
  computed: {
    startPage() {
      if (this.currentPage === 1) return 1;
      if (this.currentPage === this.totalPage) return this.totalPage - 4;
      if (this.currentPage === 2) return 1;
      return this.currentPage - 2;
    },
    endPage() {
      return Math.min(this.startPage + this.maxVisibleBtn - 1, this.totalPage);
    },
    pages() {
      let range = [];
      for (let i = this.startPage; i <= this.endPage; i++) {
        range.push({
          name: i,
          isActive: i === this.currentPage,
        });
      }
      return range;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPage;
    },
  },
  methods: {
    onClickPage(page) {
      this.$emit("pageChanged", page);
    },
  },
};
</script>

<style scoped></style>
